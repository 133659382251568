import { render, staticRenderFns } from "./IncludeItem.vue?vue&type=template&id=5860d51e&scoped=true"
import script from "./IncludeItem.vue?vue&type=script&lang=js"
export * from "./IncludeItem.vue?vue&type=script&lang=js"
import style0 from "./IncludeItem.vue?vue&type=style&index=0&id=5860d51e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5860d51e",
  null
  
)

export default component.exports